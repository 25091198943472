var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-table',{attrs:{"headings":_vm.headers,"items":_vm.records,"loading":_vm.fetching,"empty-text":_vm.emptyText},scopedSlots:_vm._u([{key:"real_name",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{staticClass:"xpl-4 xml-[-1em]",on:{"click":function($event){return _vm.$emit('navigate', item)}}},[_c('avatar',{attrs:{"size":"25","with-name":"","user":item}})],1)]}},{key:"status",fn:function(ref){
var item = ref.item;
return [(item.on_work)?_c('chip-in',{attrs:{"label":""}}):(item.on_break)?_c('chip-brb',{attrs:{"label":""}}):(item.on_out)?_c('chip-out',{attrs:{"label":""}}):_vm._e()]}},{key:"overall_work",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("secToHuman")(item.overall_work || 0," hours", " minutes"))+" ")])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('icon-list',{attrs:{"icon":"mdi-dots-horizontal"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.$emit('navigate', item)}}},[_c('v-list-item-title',{staticClass:"xtext-[#2E2E2E]"},[_c('v-icon',{attrs:{"color":"#2E2E2E","left":""}},[_vm._v(" mdi-eye-circle-outline ")]),_vm._v(" View Logs ")],1)],1),(_vm.$can.and('manage_user_ptos'))?_c('v-list-item',{on:{"click":function($event){return _vm.eventDispatch('add-pto', item)}}},[_c('v-list-item-title',{staticClass:"xtext-[#2E2E2E]"},[_c('v-icon',{attrs:{"color":"#2E2E2E","left":""}},[_vm._v(" mdi-plus-circle-outline ")]),_vm._v(" Add PTO ")],1)],1):_vm._e(),(_vm.$can.and('manage_user_time'))?_c('v-list-item',{on:{"click":function($event){return _vm.eventDispatch('add-time', item)}}},[_c('v-list-item-title',{staticClass:"xtext-[#2E2E2E]"},[_c('v-icon',{attrs:{"color":"#2E2E2E","left":""}},[_vm._v(" mdi-plus-circle-outline ")]),_vm._v(" Add Time ")],1)],1):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }