<template>
  <v-card flat min-height="100vh" color="transparent">
    <v-card-title class="xflex xflex-row xjustify-center xitems-center xw-full">
      <Filters
        :value="selectedRange"
        @change="navigate"
        type="weekly"
      ></Filters>
    </v-card-title>
    <v-card-text>
      <TeamRecordTable
        :records="records"
        :fetching="fetching"
        empty-text="No records found for the selected week"
        @navigate="goToUserRecord"
      ></TeamRecordTable>
    </v-card-text>
    <!-- <pre>{{ records }}</pre> -->
  </v-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";
import Filters from "../components/Filters.vue";
import TeamRecordTable from "../components/TeamRecordTable.vue";
export default {
  components: { Filters, TeamRecordTable },
  data() {
    return {
      fetching: false,
      records: [],
      selectedRange: [
        moment().startOf("week").format("YYYY-MM-DD"),
        moment().endOf("week").format("YYYY-MM-DD"),
      ],
    };
  },
  watch: {
    "$route.params": {
      handler: function (val) {
        this.selectedRange = val
          ? [val.start, val.end]
          : [
              moment().startOf("week").format("YYYY-MM-DD"),
              moment().endOf("week").format("YYYY-MM-DD"),
            ];
        val && this.fetchSummary(val);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  mounted() {
    this.$event.$on("refetch-ptos", () =>
      this.fetchSummary({
        start: this.selectedRange[0],
        end: this.selectedRange[1],
      })
    );
  },
  methods: {
    navigate(val) {
      this.$router
        .push({
          name: "app-team-record-weekly",
          params: { start: val[0], end: val[1] },
        })
        .catch(() => {});
    },
    goToUserRecord(item) {
      this.$router
        .push({
          name: "app-user-record-weekly",
          params: {
            id: item.id,
            start: this.selectedRange[0],
            end: this.selectedRange[1],
          },
          query: { redirect_uri: this.$route.fullPath },
        })
        .catch(() => {});
    },
    fetchSummary(range) {
      this.fetching = true;
      this.$axios
        .get(
          `api/team-records/weekly/${range.start}/${range.end}?include_status=1`
        )
        .then(({ data }) => {
          this.records = data;
          this.$event.$emit("team-record-count", data.length);
        })
        .finally(() => (this.fetching = false));
    },
  },
};
</script>

<style lang="scss" scoped></style>
